
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {downloadRequests} from '../../resources';
import {ObjectId} from 'bson';
import {getUserId} from '../../api/publicMethod';

@Component({
  name: 'privateImg',
})
export default class extends Vue {
  @Prop()
  private show: boolean;
  @Prop()
  private img: string;
  private url = '';
  created() {
    this.downloadImg();
  }
  private async downloadImg() {
    if (this.img) {
      const downloadRequest = (
        await downloadRequests.create(
          [
            {
              spec: {
                uploadId: this.img,
                userId: getUserId(),
                applicationId: ObjectId.createFromHexString(
                  localStorage.getItem('applicationId') ?? '',
                ),
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
      ).find(() => true);
      this.url = downloadRequest?.status?.url ?? '';
    }
  }
  //查看大图
  private checkimg() {
    if (this.show) {
      this.$alert(
        '<img style="max-width:100%;max-height:700px;" src="' + this.url + '">',
        '图片',
        {
          dangerouslyUseHTMLString: true,
          showConfirmButton: false,
          callback: action => {
            console.log(action);
          },
        },
      );
    }
  }
  @Watch('img')
  private changeImg() {
    this.downloadImg();
  }
}
