
import {Component, Vue} from 'vue-property-decorator';
import vueQr from 'vue-qr';
import {
  channelRequests,
  channels,
  wechatCodeRequests,
  alipayCodeRequests,
  wechatTemplates,
  applicationRequests,
  uITemplates,
  applications,
  channelApps,
  clientVersions,
  alipayTemplates,
} from '../../resources';
import {Channel, WechatMiniAppChannelSpec} from '@/externals/MaxCI-Channel-v1';
import {AlipayTemplate} from '@/externals/MaxCI-AlipayTemplate-v1';
import {ChannelApp} from '@/externals/MaxCI-ChannelApp-v1';
import {ClientVersion} from '@/externals/MaxCI-ClientVersion-v1';
import {ObjectId} from 'bson';
import {
  ChannelUpdateAuthorizationRequestSpec,
  ChannelMakeAppRequestSpec,
  ChannelUnMakeAppRequestSpec,
} from '@/externals/MaxCI-ChannelRequest-v1';
import {
  AlipayCodeRequestAuditedCancelSpec,
  AlipayCodeRequestSpec,
} from '@/externals/MaxCI-AlipayCodeRequest-v1';
import {WechatTemplate} from '@/externals/MaxCI-WechatTemplate-v1';
import {ChannelCreateRequestSpec} from '@/externals/MaxCI-ApplicationRequest-v1';
import {Form} from 'element-ui';
import {UITemplate, UITemplateSpec} from '@/externals/MaxCI-UITemplate-v1';
import lodash from 'lodash';
import Url from 'url';
import privateImg from '@/components/privateImg/index.vue';
import uploadImage from '@/components/uploadImage/index.vue';
import {Base64} from 'js-base64';
import {AxiosError} from 'axios';

interface DetailChannel extends Channel {
  appPack?: {
    newData?: ChannelApp;
    successNewData?: ChannelApp;
  };
}
@Component({
  name: 'channel',
  components: {
    privateImg,
    uploadImage,
    vueQr,
  },
})
export default class extends Vue {
  private operateAppVersion = '' as string | undefined;
  private authorizeAlipay = '';
  private operateType = '';
  private informationData = {
    subscribeMessageTemplateId: '',
  };
  private dialogInformation = false;
  private alipayRules = {
    templateVersion: [{required: true, message: '请选择', trigger: 'blur'}],
  };
  private alipayData = {
    templateVersion: '',
  };
  private dialogAlipay = false;
  private dialogPrivacy = false;
  private clientVersionInfo: null | ClientVersion = null;
  private packTitle = '';
  private dialogPack = false; //app打包信息弹窗
  private packData = {
    dcloudId: '',
    amapKey: '',
    wechat: false,
    appId: '',
    appSecret: '',
    android: false,
    package: '',
    dcloudKey: '',
    content: '',
    password: '',
    alias: '',
    icon: '',
    splash: '',
  };
  private packRules = {};
  private dialogPackInfo = false; //app打包信息弹窗
  private availableChannels: Array<string> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private templateNameData: any = {}; //渠道的模板信息
  private authorizeTitle = '';
  private dialogChannel = false; //新增渠道弹窗
  private reviewCode = '';
  private dialogReview = false;
  private title = '';
  private type: '预览' | '审核' | '撤回' | '发布' | '退回开发' = '预览';
  private dialogCode = false; //发布弹窗
  private templateId: number | null = null;
  private dialogTemplate = false; //选择版本弹窗
  private templateData: Array<WechatTemplate> = [];
  private formData = {
    channelType: '微信小程序' as
      | '微信小程序'
      | '微信公众号'
      | '移动应用'
      | '网站',
    name: '',
  };
  private rules = {
    name: [{required: true, message: '请输入渠道名称', trigger: 'blur'}],
  };
  private listShow = '0'; //0等待 1无数据 2有数据
  private dialogAdd = false; //新增弹窗
  private dialogAuthorize = false; //授权弹窗
  private dialogSet = false; //设置弹窗
  private operateId: ObjectId | null = null;
  private operateData: null | DetailChannel = null;
  private total = 0;
  private list: Array<DetailChannel> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private submitFlag = false; //提交开关
  private wechatComponentAppId = '';
  private detailId: ObjectId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private uITemplatesData: Array<UITemplate> = []; //ui模板列表
  private dialogUITemplates = false;
  private saveLoading = false;
  private alipayTemplateData: Array<AlipayTemplate> = []; //小程序模板版本号
  async created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    try {
      //查询App渠道最新版本
      this.clientVersionInfo =
        (
          await clientVersions.find(stage =>
            stage.$match(match =>
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.detailId),
              ),
            ),
          )
        ).find(() => true) ?? null;

      //查询应用信息
      const application = (
        await applications.find(stage =>
          stage.$match(match =>
            match(
              f => f('_id'),
              e => e.$eq(this.detailId),
            ),
          ),
        )
      ).find(() => true);
      this.availableChannels =
        application?.spec?.quota?.availableChannels ?? [];

      //微信模板
      this.templateData = await wechatTemplates.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.detailId),
          ),
        ),
      );
      this.templateData.sort((a: WechatTemplate, b: WechatTemplate) => {
        return b.spec.id - a.spec.id;
      });
      this.updateList();
      this.wechatComponentAppId =
        this.platformConfig?.wechatComponentAppId ?? '';
      //ui模板
      this.uITemplatesData = await uITemplates.find(stage =>
        stage.$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.detailId),
          );
          return match;
        }),
      );
    } catch (e) {
      console.log(e);
    }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //消息模板id配置弹窗
  private informationPopUps(item: {
    _id: ObjectId;
    spec: WechatMiniAppChannelSpec;
  }) {
    this.operateId = item._id;
    this.informationData.subscribeMessageTemplateId =
      item.spec.subscribeMessageTemplateId ?? '';
    this.dialogInformation = true;
  }
  //消息模板id配置弹窗
  private informationSumbit() {
    (this.$refs.informationData as Form).validate(async valid => {
      if (valid) {
        try {
          this.saveLoading = true;
          const channelRequest = await channelRequests.create(
            [
              {
                spec: {
                  type: '更新微信小程序',
                  applicationId: this.detailId,
                  channelId: this.operateId as ObjectId,
                  subscribeMessageTemplateId:
                    this.informationData.subscribeMessageTemplateId,
                },
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          );
          if (channelRequest[0].status?.phase === '成功') {
            this.$message.success('操作成功');
            this.dialogInformation = false;
            this.updateList();
          } else {
            this.$message.error(
              channelRequest[0].status?.conditions[0].message ?? '',
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error(e);
        } finally {
          this.saveLoading = false;
        }
      } else {
        return false;
      }
    });
  }
  //是否升级
  private isUpgrade(item: DetailChannel) {
    let status = false;
    let current = '';
    if (item.appPack) {
      if (item.appPack.newData?.status?.phase === '发布') {
        current = item.appPack?.newData?.spec.version ?? '';
        if (current && this.clientVersionInfo) {
          const currentData = current.split('.');
          const versionData = this.clientVersionInfo.spec.version.split('.');
          if (
            Number(versionData[0]) > Number(currentData[0]) ||
            Number(versionData[1]) > Number(currentData[1]) ||
            Number(versionData[2]) > Number(currentData[2])
          ) {
            status = true;
          }
        }
      } else {
        current = item.appPack?.successNewData?.spec.version ?? '';
      }
    }

    return status;
  }
  //触发选择证书文件
  private selectFile() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file = document.getElementById('file') as any;
    file.click();
  }
  //选择证书文件
  private changeFile() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file = document.getElementById('file') as any;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file.files[0]);
    reader.onload = e => {
      if (e?.target?.result) {
        this.packData.content = Base64.fromUint8Array(
          new Uint8Array(e?.target?.result as ArrayBuffer),
        );
      }
    };
  }
  //自动打包保存
  private packSumbit() {
    let data = {} as ChannelUnMakeAppRequestSpec | ChannelMakeAppRequestSpec;
    if (this.operateData) {
      if (this.packTitle === '生成App') {
        data = {
          type: '生成移动应用',
          applicationId: this.detailId,
          channelId: this.operateData._id as ObjectId,
          platform: 'android',
        };
      } else {
        data = {
          type: '取消生成移动应用',
          applicationId: this.detailId,
          channelId: this.operateData._id as ObjectId,
          channelAppId: this.operateData.appPack?.newData?._id as ObjectId,
        };
      }
    }
    this.makeApp(data);
  }
  //打包
  private makeApp(
    data: ChannelUnMakeAppRequestSpec | ChannelMakeAppRequestSpec,
  ) {
    this.saveLoading = true;
    try {
      channelRequests
        .create(
          [
            {
              spec: data,
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        )
        .then(res => {
          if (res[0].status?.phase === '成功') {
            this.saveLoading = false;
            this.$message.success('操作成功');
            this.dialogPack = false;
            this.dialogPackInfo = false;
            this.updateList();
          } else {
            this.$message.error(res[0].status?.conditions[0].message ?? '');
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.saveLoading = false;
      this.$message.error(e);
    }
  }
  //自动打包弹窗
  private packPopUps(item: DetailChannel, type: string) {
    this.dialogPack = true;
    this.operateData = item;
    this.packTitle = type;
  }
  //选择app图标图片
  private changeIconImage(id: string) {
    this.packData.icon = id;
  }
  //选择app启动页图片
  private changeSplashImage(id: string) {
    this.packData.splash = id;
  }
  //更新支付宝小程序弹窗
  private async alipayPopUps(item: DetailChannel) {
    this.operateId = item._id;
    //查询小程序模板版本号
    this.alipayTemplateData = await alipayTemplates.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.detailId),
        ),
      ),
    );
    if (this.alipayTemplateData.length === 0) {
      this.$message.error('暂无可选模板');
      return;
    }
    this.alipayData = {
      templateVersion: '',
    };
    this.dialogAlipay = true;
  }
  //更新支付宝小程序
  private async alipaySumbit() {
    (this.$refs.alipayData as Form).validate(async valid => {
      if (valid) {
        try {
          this.saveLoading = true;
          const alipayCodeRequest = await alipayCodeRequests.create(
            [
              {
                spec: {
                  applicationId: this.detailId,
                  channelId: this.operateId as ObjectId,
                  type: '上传',
                  templateVersion: this.alipayData.templateVersion,
                },
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          );
          if (alipayCodeRequest[0].status?.phase === '成功') {
            this.$message.success('操作成功');
            this.dialogAlipay = false;
            this.updateList();
          } else {
            this.$message.error(
              alipayCodeRequest[0].status?.conditions[0].message ?? '',
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error(e);
        } finally {
          this.submitFlag = false;
          this.saveLoading = false;
        }
      } else {
        return false;
      }
    });
  }
  //更新app打包信息
  private async packInfoSumbit() {
    (this.$refs.packData as Form).validate(async valid => {
      if (valid) {
        try {
          this.saveLoading = true;
          const channelRequest = await channelRequests.create(
            [
              {
                spec: {
                  type: '更新移动应用',
                  applicationId: this.detailId,
                  channelId: this.operateId as ObjectId,
                  ...(this.packData.dcloudId
                    ? {dcloudId: this.packData.dcloudId}
                    : {}),
                  ...(this.packData.amapKey
                    ? {amapKey: this.packData.amapKey}
                    : {}),
                  ...(this.packData.wechat
                    ? {
                        wechat: {
                          appId: this.packData.appId,
                          appSecret: this.packData.appSecret,
                        },
                      }
                    : {}),
                  ...(this.packData.android
                    ? {
                        android: {
                          package: this.packData.package,
                          dcloudKey: this.packData.dcloudKey,
                          key: {
                            content: this.packData.content,
                            password: this.packData.password,
                            alias: this.packData.alias,
                          },
                          resource: {
                            icon: this.packData.icon,
                            ...(this.packData.splash
                              ? {splash: this.packData.splash}
                              : {}),
                            ...(this.packData.splash
                              ? {push: this.packData.splash}
                              : {}),
                          },
                        },
                      }
                    : {}),
                },
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          );
          if (channelRequest[0].status?.phase === '成功') {
            this.makeApp({
              type: '生成移动应用',
              applicationId: this.detailId,
              channelId: this.operateId as ObjectId,
              platform: 'android',
            });
          } else {
            this.$message.error(
              channelRequest[0].status?.conditions[0].message ?? '',
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error(e);
        } finally {
          this.submitFlag = false;
          this.saveLoading = false;
        }
      } else {
        return false;
      }
    });
  }
  //app打包弹窗
  private packInfoPopUps(item: DetailChannel) {
    this.operateId = item._id;
    if (item.spec.type === '移动应用') {
      this.packData.dcloudId = item.spec.dcloudId ?? '';
      this.packData.amapKey = item.spec.amapKey ?? '';
      if (item.spec.wechat) {
        this.packData.wechat = true;
        this.packData.appId = item.spec.wechat.appId;
        this.packData.appSecret = item.spec.wechat.appSecret;
      } else {
        this.packData.wechat = false;
        this.packData.appId = '';
        this.packData.appSecret = '';
      }
      if (item.spec.android) {
        this.packData.android = true;
        this.packData.package = item.spec.android.package;
        this.packData.dcloudKey = item.spec.android.dcloudKey;
        this.packData.content = item.spec.android.key.content;
        this.packData.password = item.spec.android.key.password;
        this.packData.alias = item.spec.android.key.alias;
        this.packData.icon = item.spec.android.resource.icon;
        this.packData.splash = item.spec.android.resource.splash ?? '';
      } else {
        this.packData.android = false;
        this.packData.package = '';
        this.packData.dcloudKey = '';
        this.packData.content = '';
        this.packData.password = '';
        this.packData.alias = '';
        this.packData.icon = '';
        this.packData.splash = '';
      }
    }
    this.dialogPackInfo = true;
  }
  private getTemplateName(id: ObjectId) {
    let name = '';
    if (id && this.templateNameData[id.toHexString()]) {
      name = this.templateNameData[id.toHexString()].spec.name;
    }
    return name;
  }
  //获取页面缩略图
  private getImage(spec: UITemplateSpec) {
    if (typeof spec.index === 'string') {
      const url = Url.parse(spec.index ?? '', true);
      return spec.pages[url.query.page as string] &&
        spec.pages[url.query.page as string].image
        ? spec.pages[url.query.page as string].image
        : '';
    }
  }
  //复制下载链接
  private copyApplink(appPack: {
    newData?: ChannelApp;
    successNewData?: ChannelApp;
  }) {
    let url = '';
    if (appPack.newData?.status?.phase === '发布') {
      url =
        this.platformConfig.downloadUrl + appPack.newData?.status?.release ??
        '';
    } else {
      url =
        this.platformConfig.downloadUrl +
          appPack.successNewData?.status?.release ?? '';
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let input = document.getElementById('inputCopy') as any;
    input.value = url; // 修改文本框的内容
    input.select(); // 选中文本
    document.execCommand('copy'); // 执行浏览器复制命令
    this.$message.success('复制成功');
  }
  //复制链接
  private copyBtn(item: DetailChannel, type: string) {
    const currentUrl = Url.parse(window.location.href ?? '', true);
    const host = `${currentUrl.protocol}//${currentUrl.host}/#/login`;
    const url = `${host}?applicationId=${
      this.detailId
    }&channelId=${item._id.toHexString()}&type=${type}`;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let input = document.getElementById('inputCopy') as any;
    input.value = url; // 修改文本框的内容
    input.select(); // 选中文本
    document.execCommand('copy'); // 执行浏览器复制命令
    this.$message.success('复制成功');
  }
  //选择ui模板保存
  private async uITemplatesSubmit(index: number) {
    try {
      this.saveLoading = true;
      const channelRequest = await channelRequests.create(
        [
          {
            spec: {
              type: '绑定模板',
              applicationId: this.detailId,
              channelId: this.operateId as ObjectId,
              templateId: this.uITemplatesData[index]._id,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      if (channelRequest[0].status?.phase === '成功') {
        this.$message.success('绑定成功');
        this.dialogUITemplates = false;
        this.updateList();
      } else {
        this.$message.error(
          channelRequest[0].status?.conditions[0].message ?? '',
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }
  //选择ui模板弹窗
  private uITemplatePopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogUITemplates = true;
  }
  //获取版本信息
  private getTemplate(id: number) {
    let name = '';
    this.templateData.forEach(item => {
      if (id === item.spec.id) {
        name = item.spec.version;
      }
    });
    return name;
  }
  //获取表格
  private async updateList() {
    this.listLoading = true;
    try {
      const list = (await channels.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.detailId),
          )(
            f => f('spec')('type'),
            e => e.$in(['微信小程序', '移动应用', '网站', '支付宝小程序']),
          ),
        ),
      )) as Array<DetailChannel>;
      //获取模板，app打包记录
      let templateIdData: Array<ObjectId> = [];
      let channelIdData: Array<ObjectId> = [];
      list.forEach(item => {
        if (item.spec.templateId) {
          templateIdData.push(item.spec.templateId);
        }
        if (item.spec.type === '移动应用') {
          channelIdData.push(item._id);
        }
      });
      templateIdData = Array.from(new Set(templateIdData));
      const templateData = await uITemplates.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$in(templateIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.detailId),
          );
          return match;
        }),
      );
      this.templateNameData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        templateData.map(v => v._id.toHexString()) as any,
        templateData,
      );
      channelIdData = Array.from(new Set(channelIdData));
      const channelData = await channelApps.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('channelId'),
              e => e.$in(channelIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.detailId),
            ),
          )
          .$sort(sort => sort(f => f('metadata')('creationTimestamp'), '降序'))
          .$facet(facet =>
            facet('newData', newDataStage =>
              newDataStage.$group(
                e => e.$fieldPath(f => f('spec')('channelId')),
                group =>
                  group('channel', e =>
                    e.$first(first => first.$fieldPath(f => f('$ROOT'))),
                  ),
              ),
            )('successNewData', successNewDataStage =>
              successNewDataStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$eq('发布'),
                  ),
                )
                .$group(
                  e => e.$fieldPath(f => f('spec')('channelId')),
                  group =>
                    group('channel', e =>
                      e.$first(first => first.$fieldPath(f => f('$ROOT'))),
                    ),
                ),
            ),
          ),
      );
      list.forEach(item => {
        channelData.forEach(channelItem => {
          if (
            channelItem.newData[0] &&
            item._id.equals(channelItem.newData[0].channel.spec.channelId)
          ) {
            item.appPack = {
              newData:
                channelItem.newData.length > 0
                  ? channelItem.newData[0].channel
                  : undefined,
              successNewData:
                channelItem.successNewData.length > 0
                  ? channelItem.successNewData[0].channel
                  : undefined,
            };
          }
        });
      });
      if (list.length === 0) {
        this.listShow = '1';
      } else {
        this.listShow = '2';
      }
      this.list = list;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //操作代码弹窗
  private codePopUps(
    id: ObjectId,
    type: '预览' | '审核' | '撤回' | '发布' | '退回开发',
    operateType: string,
    appVersion?: string,
  ) {
    this.operateAppVersion = appVersion;
    this.operateType = operateType;
    this.operateId = id;
    this.type = type;
    if (type === '预览') {
      this.reviewCode = '';
      if (this.operateType === '支付宝') {
        this.dialogReview = true;
      }
      this.codeSumbit();
    } else {
      this.title = '确定' + type;
      this.dialogCode = true;
    }
  }
  //操作代码保存 this.saveLoading = false;
  private async codeSumbit() {
    if (this.operateId) {
      if (this.operateType === '微信') {
        const wechatCodeRequest = await wechatCodeRequests.create(
          [
            {
              spec: {
                applicationId: this.detailId,
                channelId: this.operateId,
                type: this.type as '预览' | '审核' | '撤回' | '发布',
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
            },
            fullResource: true,
          },
        );
        if (wechatCodeRequest[0].status?.phase === '成功') {
          this.operateId = null;
          this.dialogCode = false;
          if (this.type === '预览') {
            this.dialogReview = true;
            if (wechatCodeRequest[0].status?.qrcode) {
              const myArray = wechatCodeRequest[0].status?.qrcode;
              const blob = new Blob([myArray.buffer], {type: 'image/jpeg'});
              this.reviewCode = URL.createObjectURL(blob);
            }
          }
          this.updateList();
        } else {
          this.$message.error(
            wechatCodeRequest[0].status?.conditions[0].message ?? '',
          );
        }
      } else {
        this.saveLoading = true;
        if (this.type === '预览') {
          while (this.dialogReview) {
            const alipayCodeRequest = await alipayCodeRequests.create(
              [
                {
                  spec: {
                    applicationId: this.detailId,
                    channelId: this.operateId,
                    type: this.type,
                  },
                },
              ],
              {
                watch: {
                  filter: filter =>
                    filter(
                      f => f('operationType'),
                      e => e.$eq('update'),
                    )(
                      f => f('fullDocument')('status')('phase'),
                      e => e.$exists(true),
                    ),
                },
                fullResource: true,
              },
            );
            if (alipayCodeRequest[0].status?.phase === '成功') {
              this.saveLoading = false;
              this.operateId = null;
              if (this.type === '预览') {
                if (alipayCodeRequest[0].status?.qrcode) {
                  this.reviewCode = alipayCodeRequest[0].status?.qrcode;
                }
              }
              break;
            } else {
              if (
                alipayCodeRequest[0].status?.conditions[0].message ===
                '体验版打包中'
              ) {
                break;
              }
              this.$message.error(
                alipayCodeRequest[0].status?.conditions[0].message ?? '',
              );
              this.dialogReview = false;
              this.saveLoading = false;
            }
            await new Promise<void>(resolve => {
              const interval = setInterval(() => {
                if (!this.dialogReview) {
                  clearInterval(interval);
                  resolve();
                }
              }, 1000);
              setTimeout(() => {
                clearInterval(interval);
                resolve();
              }, 5000);
            });
          }
        } else {
          let spec = {} as
            | AlipayCodeRequestAuditedCancelSpec
            | AlipayCodeRequestSpec;
          if (this.type === '退回开发') {
            spec = {
              applicationId: this.detailId,
              channelId: this.operateId,
              type: '退回开发',
              appVersion: this.operateAppVersion ?? '',
            };
          } else {
            spec = {
              applicationId: this.detailId,
              channelId: this.operateId,
              type: this.type as '审核' | '撤回' | '发布',
            };
          }
          const alipayCodeRequest = await alipayCodeRequests.create(
            [
              {
                spec: spec,
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          );
          if (alipayCodeRequest[0].status?.phase === '成功') {
            this.operateId = null;
            this.dialogCode = false;
            this.updateList();
          } else {
            this.$message.error(
              alipayCodeRequest[0].status?.conditions[0].message ?? '',
            );
          }
          this.saveLoading = false;
        }
      }
    } else {
      this.$message.error('请选择版本');
    }
  }
  //选择模板弹窗
  private templatePopUps(id: ObjectId) {
    this.templateId = null;
    this.operateId = id;
    this.dialogTemplate = true;
  }
  //选择模板保存
  private async templateSumbit() {
    if (this.templateId !== null && this.operateId) {
      this.saveLoading = true;
      try {
        const wechatCodeRequest = await wechatCodeRequests.create(
          [
            {
              spec: {
                type: '上传',
                applicationId: this.detailId,
                channelId: this.operateId,
                templateId: this.templateId,
              },
            },
          ],
          {
            watch: {
              filter: filter =>
                filter(
                  f => f('operationType'),
                  e => e.$eq('update'),
                )(
                  f => f('fullDocument')('status')('phase'),
                  e => e.$exists(true),
                ),
              timeout: 20000,
            },
            fullResource: true,
          },
        );
        if (wechatCodeRequest[0].status?.phase === '成功') {
          this.templateId = null;
          this.operateId = null;
          this.dialogTemplate = false;
          this.updateList();
        } else {
          this.$message.error(
            wechatCodeRequest[0].status?.conditions[0].message ?? '',
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.isAxiosError) {
          const axiosError = e as AxiosError;
          if (axiosError.response?.status === 408) {
            this.$message.error('请求超时，请稍后重试');
          } else {
            this.$message.error(axiosError.response?.data.message);
          }
        }
      } finally {
        this.saveLoading = false;
      }
    } else {
      this.$message.error('请选择版本');
    }
  }
  //更新隐私指引弹窗
  private privacyPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogPrivacy = true;
  }
  //更新隐私指引保存
  private async privacySumbit() {
    try {
      this.saveLoading = true;
      const channelRequest = await channelRequests.create(
        [
          {
            spec: {
              type: '更新隐私指引',
              applicationId: this.detailId,
              channelId: this.operateId as ObjectId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      if (channelRequest[0].status?.phase === '成功') {
        this.$message.success('更新成功');
        this.dialogPrivacy = false;
        this.updateList();
      } else {
        this.$message.error(
          channelRequest[0].status?.conditions[0].message ?? '',
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.saveLoading = false;
    }
  }
  //授权弹窗
  private async authorizePopUps(
    id: ObjectId,
    type: string,
    operateType: string,
  ) {
    this.operateId = id;
    this.dialogAuthorize = true;
    this.operateType = operateType;
    if (type === '更新授权') {
      this.authorizeTitle = '确定更新授权？';
    } else {
      this.authorizeTitle = `绑定${operateType}`;
    }
    if (this.operateType === '支付宝') {
      this.authorizeAlipay = '';
      const redirect_uri = encodeURI(
        new URL('/#/authorize', window.location.origin).href,
      );
      console.log('redirect_uri', redirect_uri);
      const channelRequest = await channelRequests.create(
        [
          {
            spec: {
              type: '更新授权',
              applicationId: this.detailId,
              channelId: id,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      if (channelRequest[0].status?.phase === '成功') {
        const link = new URL(
          'https://openauth.alipay.com/oauth2/appToAppBatchAuth.htm',
        );
        link.searchParams.set('app_id', channelRequest[0].status?.appId ?? '');
        link.searchParams.set('application_type', 'TINYAPP');
        link.searchParams.set('redirect_uri', redirect_uri);
        link.searchParams.set('state', `${id.toHexString()}A`);
        console.log(link.href);
        this.authorizeAlipay = link.href;
      } else {
        this.$message.error(
          channelRequest[0].status?.conditions[0].message ?? '',
        );
      }
    }
  }
  //更新授权保存
  private authorizeSumbit() {
    try {
      if (this.operateId) {
        if (this.operateType === '微信') {
          const data = {
            type: '更新授权',
            channelId: this.operateId,
            applicationId: this.detailId,
          } as ChannelUpdateAuthorizationRequestSpec;
          this.operateSumbit('authorize', data);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  // 添加弹窗
  private addPopUps() {
    this.dialogAdd = true;
  }
  // 添加保存
  private addSumbit() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          this.submitFlag = true;
          const data = {
            type: '创建渠道',
            channelType: this.formData.channelType,
            name: this.formData.name,
            applicationId: this.detailId,
          } as ChannelCreateRequestSpec;
          const applicationRequest = await applicationRequests.create(
            [
              {
                spec: data,
              },
            ],
            {
              watch: {
                filter: filter =>
                  filter(
                    f => f('operationType'),
                    e => e.$eq('update'),
                  )(
                    f => f('fullDocument')('status')('phase'),
                    e => e.$exists(true),
                  ),
              },
              fullResource: true,
            },
          );
          if (applicationRequest[0].status?.phase === '成功') {
            this.$message.success('新增成功');
            this.dialogAdd = false;
            this.updateList();
          } else {
            this.$message.error(
              applicationRequest[0].status?.conditions[0].message ?? '',
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          console.log(e);
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //操作提交
  private operateSumbit(
    type: string,
    data: ChannelUpdateAuthorizationRequestSpec,
  ) {
    channelRequests
      .create(
        [
          {
            spec: data,
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.phase === '成功') {
          if (type === 'set') {
            this.$message.success('设置成功');
            this.dialogSet = false;
          } else {
            const href = encodeURIComponent(window.location.href);
            const jumpLink =
              'https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=' +
              this.wechatComponentAppId +
              '&pre_auth_code=' +
              res[0].status.preAuthCode +
              '&redirect_uri=' +
              href +
              '&auth_type=2';
            window.location.href = jumpLink;
          }
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
}
